// import { UpdatedPOInformation } from '../../../components/ReceivingPO/RegularPOList';
import {
  PurchaseOrder,
  SelectedPOToReceiveState,
  UpdatedPOInformation,
} from '../../../types/types';
import { GlobalContextAction } from '../globalReducer';
import { SelectedPOToReceiveActionType } from './selectedPOToReceiveActions';

export const selectedPOToReceiveReducer = (
  state: SelectedPOToReceiveState,
  action: GlobalContextAction
): SelectedPOToReceiveState => {
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (action.type) {
    case SelectedPOToReceiveActionType.SET_SELECTED_PO_TO_RECEIVE:
      return {
        ...state,
        data: action.payload as PurchaseOrder,
      };
    case SelectedPOToReceiveActionType.SET_UPDATED_PO_DATA:
      return {
        ...state,
        updatedPO: action.payload as UpdatedPOInformation[],
      };
    default:
      return {
        ...state,
      };
  }
};
