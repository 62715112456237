import React from 'react';
import { CircularProgress, makeStyles } from '@rentacenter/racstrap';

const useStyles = makeStyles((theme: any) => ({
  loading: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)', //todo import color from racstrap
    zIndex: 99999,
  },
  loadingProgress: {
    marginTop: theme.typography.pxToRem(-40),
    marginLeft: theme.typography.pxToRem(-40),
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}));

export const LoadingOverlay = () => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <div className={classes.loadingProgress}>
        <CircularProgress />
      </div>
    </div>
  );
};
