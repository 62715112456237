import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Icon,
  makeStyles,
  RACCheckBox,
  RACTable,
  RACTableCell,
  RACTableRow,
  RACTooltip,
  RACTextField,
  RACCOLOR,
} from '@rentacenter/racstrap';
import { CancelTokenSource } from 'axios';
import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { RACInfiniteScroll } from '../../common/RACInfiniteScroll/RACInfiniteScroll';
import { getCancelTokenSource } from '../../api/client';
import { getPurchaseOrders } from '../../api/purchaseOrders';
import {
  COLUMN_TEXT_LIMIT,
  INITIAL_OFFSET,
  POSection,
  RegularPOStatusTypes,
  ReceiveNowType,
  RECEIVING_PO_API_LIMIT,
  RegularPOTypes,
} from '../../constants/constants';
import { ReceiveNowActions } from './ReceiveNowActions';
import {
  GetPurchaseOrderRequest,
  GetPurchaseOrderResponse,
  PurchaseOrder,
  PurchaseOrderDetail,
  UpdatedPOInformation,
} from '../../types/types';
import {
  formatDateString,
  formatMoney,
  getPONumber,
  getSelectedStore,
} from '../../utils/utils';
import {
  useSearchActions,
  useSearchDetails,
} from '../../context/Search/SearchProvider';
import { FieldWithTooltip } from '../../common/FieldWithTooltip/FieldWithTooltip';
import { AutoNumeric } from '../../common/AutoNumeric/AutoNumeric';
import { useGlobalContext } from '../../context/global/GlobalContext';
import {
  setSelectedPOToReceive,
  setUpdatedPOData,
} from '../../context/global/reducer/selectedPOToReceiveActions';
import { useUserStateContext } from '../../context/user/user-contexts';

export const regularPOListTestId = 'regularPOListTestId';

const useClasses = makeStyles((theme: any) => ({
  purchaseOrderListWrapper: {
    width: '100%',
    height: theme.typography.pxToRem(284),
  },
  purchaseOrderList: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    borderRadius: '1rem',
    overflowX: 'auto',
    height: theme.typography.pxToRem(297),
  },
  paddingTopBottom8: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  tableHeader: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    backgroundColor: 'white',
  },
  contentHeight: {
    height: theme.typography.pxToRem(233),
  },
  mainTableCell: {
    fontWeight: 'bolder',
    fontSize: '1rem',
    paddingright: '1rem',
  },
  childTableCellWidth50: {
    width: theme.typography.pxToRem(50),
  },
  childTableCellWidth130: {
    width: theme.typography.pxToRem(130),
  },
  childTableCellWidth73: {
    width: theme.typography.pxToRem(44),
    minWidth: theme.typography.pxToRem(44),
  },
  minWidth100: {
    minWidth: theme.typography.pxToRem(125),
  },
  childTableCellWidth100: {
    width: theme.typography.pxToRem(125),
  },
  childTableCellWidth150: {
    width: theme.typography.pxToRem(150),
  },
  childTableCellWidth170: {
    width: theme.typography.pxToRem(170),
  },
  childTableCellWidth250: {
    width: theme.typography.pxToRem(250),
  },
  childTableCellWidth: {
    width: theme.typography.pxToRem(180),
  },
  mainTableCellWrapper: {
    display: 'flex',
  },
  mainTableCellWidth100: {
    width: theme.typography.pxToRem(125),
  },
  fieldWidth70percent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
    minWidth: theme.typography.pxToRem(600),
  },
  checkboxChecked: {
    display: 'flex',
  },
  disabled: {
    opacity: '.5',
  },
  mainCheckbox: {
    width: `${theme.typography.pxToRem(40)} !important`,
  },
  checkbox: {
    padding: '0',
    width: theme.typography.pxToRem(100),
    height: theme.typography.pxToRem(50),
    '& svg': {
      width: theme.typography.pxToRem(26),
      height: theme.typography.pxToRem(26),
    },
  },
  receivedDetailContainer: {
    display: 'inline-flex',
    paddingLeft: theme.typography.pxToRem(50),
    fontSize: theme.typography.pxToRem(14),
    width: '100%',
  },
  receivedDetailLabel: {
    fontWeight: 'bolder',
  },
  receivedDetailValue: {
    fontSize: theme.typography.pxToRem(14),
  },
  receivedDetailItem: {
    width: theme.typography.pxToRem(200),
    marginRight: '5%',
  },
  receivedDetailComment: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(300),
    marginRight: '5%',
  },
  receivedDetailDateReceived: {
    width: '100%',
    maxWidth: theme.typography.pxToRem(300),
    display: 'flex',
  },
  receivedDetailDateReceivedLabel: {
    marginRight: theme.typography.pxToRem(10),
  },
  mainCellContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  mainCellDateColumn: {
    display: 'flex',
    flexGrow: 4,
    justifyContent: 'end',
  },
  marginRight5: {
    marginRight: theme.typography.pxToRem(5),
  },
  mainTableDateCell: {
    marginLeft: theme.typography.pxToRem(47),
  },
  field: {
    width: '12rem',
    '& input': {
      height: theme.typography.pxToRem(25),
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: '6%',
  },
  icon: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(20),
    height: '100%',
  },
  fieldFlex: {
    display: 'flex',
    justifyContent: 'end',
    width: theme.typography.pxToRem(10),
  },
}));

export const trimText = (text: string | undefined) => {
  if (!text) return '';

  if (text.length > COLUMN_TEXT_LIMIT)
    return `${text.substring(0, COLUMN_TEXT_LIMIT)}...`;

  return text;
};

export const trimTextWithTooltip = (
  text: string | undefined,
  length: number = COLUMN_TEXT_LIMIT
) => {
  if (!text) return '';

  if (text.length > length) {
    return (
      <RACTooltip title={text}>
        <span>{trimText(text)}</span>
      </RACTooltip>
    );
  }

  return text;
};

export interface ReceivedDetailRowProps {
  isDisabled?: boolean;
  isPartial?: boolean;
  poReceivedDetail: any;
  isPreviouslyReceived: boolean;
  partialReceivedPO: UpdatedPOInformation[];
  index: number;
  dateReceivedColumnNeeded?: boolean;
}

// export interface UpdatedPOInformation {
//   purchaseOrderId: string;
//   purchaseOrderDetailId: string;
//   purchaseOrderDetailIndex?: number;
//   purchaseOrderDetailReceivedId?: string;
//   poLineItemReceivedId?: string;
//   comment?: string;
//   reason?: string;
//   action?: string;
//   manufacturerSerialNumber?: string | null;
//   billOfLading?: string | null;
//   poType?: string;
// }

export const generateFlatReceivedDetails = (
  poReceivedDetails: PurchaseOrderDetail[]
) => {
  const flatPOReceivedDetails: any[] = [];

  poReceivedDetails.forEach((item) => {
    if (item?.quantityOrdered !== item?.receivedCount) {
      const totalPONotReceived =
        item?.quantityOrdered - (item?.receivedCount || 0);
      for (let index = 0; index < totalPONotReceived; index++) {
        flatPOReceivedDetails.push(item);
      }
    }

    if (item.purchaseOrderReceivedDetails?.length) {
      item.purchaseOrderReceivedDetails.forEach((detail) => {
        flatPOReceivedDetails.push({
          ...item,
          ...detail,
        });
      });
    }
  });
  return flatPOReceivedDetails;
};

export const ReceivedDetailRow = ({
  index,
  partialReceivedPO,
  poReceivedDetail,
  isDisabled,
  isPartial,
  isPreviouslyReceived,
  dateReceivedColumnNeeded = false,
}: ReceivedDetailRowProps) => {
  const classes = useClasses();
  const piecesPerItems = isPartial
    ? 0
    : poReceivedDetail?.receivedLineItemDetails?.[0].piecesReceived || 0;
  const updatedPartialReceivedPO = partialReceivedPO.find(
    (item) =>
      (!!item.poLineItemReceivedId &&
        item.poLineItemReceivedId ===
          poReceivedDetail?.receivedLineItemDetails?.[0]
            .poLineItemReceivedId) ||
      (!item?.poLineItemReceivedId &&
        item.purchaseOrderDetailId === poReceivedDetail.purchaseOrderDetailId &&
        item.purchaseOrderDetailIndex === index)
  );
  let comments = '-';
  if (updatedPartialReceivedPO) {
    if (updatedPartialReceivedPO.action === ReceiveNowType.Partial) {
      comments = updatedPartialReceivedPO.comment || '-';
    }
  } else {
    comments =
      poReceivedDetail?.receivedLineItemDetails?.[0].receivedComments || '-';
  }

  let previouslyReceived = 'No';
  if (isPreviouslyReceived) {
    previouslyReceived = 'All';
  } else if (isPartial) {
    previouslyReceived = 'Partial';
  }

  return (
    <RACTableRow backgroundColor={RACCOLOR.ALICE_BLUE}>
      <RACTableCell
        classes={{
          root: clsx(classes.paddingTopBottom8, isDisabled && classes.disabled),
        }}
        colSpan={10}
      >
        <div className={classes.receivedDetailContainer}>
          <div className={classes.receivedDetailItem}>
            <span className={classes.receivedDetailLabel}>MFG. Model # </span>
            <span className={classes.receivedDetailValue}>
              {'  '}
              {trimTextWithTooltip(poReceivedDetail?.modelNumber) || ''}
            </span>
          </div>
          <div className={classes.receivedDetailItem}>
            <span className={classes.receivedDetailLabel}>Pieces/Items </span>
            <span className={classes.receivedDetailValue}>
              {` ${piecesPerItems}`}
            </span>
          </div>
          <div className={classes.receivedDetailItem}>
            <span className={classes.receivedDetailLabel}>
              Previously Received
            </span>
            <span className={classes.receivedDetailValue}>
              {`  ${previouslyReceived}`}
            </span>
          </div>
          <div className={classes.receivedDetailComment}>
            <span className={classes.receivedDetailLabel}>Comments </span>
            <span
              className={classes.receivedDetailValue}
            >{`  ${trimTextWithTooltip(comments)}`}</span>
          </div>
          {dateReceivedColumnNeeded && (
            <div className={classes.receivedDetailDateReceived}>
              <div className={classes.receivedDetailDateReceivedLabel}>
                Date Received{' '}
              </div>
              <div className={classes.receivedDetailValue}>
                {formatDateString(
                  poReceivedDetail?.receivedLineItemDetails?.[0]
                    .poLineItemReceivedDate
                ) || ''}
              </div>
            </div>
          )}
        </div>
      </RACTableCell>
    </RACTableRow>
  );
};

const poExistInUpdatedList = (
  hasPOLineItemReceivedId: boolean,
  poReceived: any,
  index: number,
  partialReceivedPOs: UpdatedPOInformation[]
) =>
  partialReceivedPOs?.find(
    (item) =>
      (hasPOLineItemReceivedId &&
        item.poLineItemReceivedId ===
          poReceived?.receivedLineItemDetails?.[0].poLineItemReceivedId) ||
      (!hasPOLineItemReceivedId &&
        item.purchaseOrderDetailId === poReceived?.purchaseOrderDetailId &&
        index === item.purchaseOrderDetailIndex)
  );

export const handlePartialRefusalSubmitted = (
  purchaseOrderId: string,
  reason: string,
  comment: string,
  poReceived: any,
  index: number,
  action: string,
  partialReceivedPOs: UpdatedPOInformation[],
  poType = ''
): UpdatedPOInformation[] => {
  const hasPOLineItemReceivedId =
    !!poReceived?.receivedLineItemDetails?.[0].poLineItemReceivedId;
  const partialPO = poExistInUpdatedList(
    hasPOLineItemReceivedId,
    poReceived,
    index,
    partialReceivedPOs
  );

  if (partialPO) {
    const otherPartialPos = partialReceivedPOs?.filter(
      (item) =>
        (hasPOLineItemReceivedId &&
          item.poLineItemReceivedId !==
            poReceived?.receivedLineItemDetails?.[0].poLineItemReceivedId) ||
        (!hasPOLineItemReceivedId &&
          item.purchaseOrderDetailId === poReceived?.purchaseOrderDetailId &&
          index !== item.purchaseOrderDetailIndex) ||
        item.purchaseOrderDetailId !== poReceived?.purchaseOrderDetailId
    );

    if (
      poReceived.manufacturerSerialNumber &&
      !partialPO.manufacturerSerialNumber
    ) {
      partialPO.manufacturerSerialNumber = poReceived.manufacturerSerialNumber;
    }

    return [
      ...otherPartialPos,
      {
        ...partialPO,
        comment,
        reason,
        action,
        billOfLading: '',
        poType: poType,
      },
    ];
  } else {
    const partial: UpdatedPOInformation = {
      purchaseOrderId,
      purchaseOrderDetailId: poReceived?.purchaseOrderDetailId,
      purchaseOrderDetailIndex: index,
      purchaseOrderDetailReceivedId: poReceived?.purchaseOrderDetailReceivedId,
      poLineItemReceivedId:
        poReceived?.receivedLineItemDetails?.[0].poLineItemReceivedId,
      comment,
      reason,
      action,
      billOfLading: '',
    };

    if (poReceived.manufacturerSerialNumber) {
      partial.manufacturerSerialNumber = poReceived.manufacturerSerialNumber;
    }

    partial.poType = poType;

    return [...partialReceivedPOs, partial];
  }
};

export const addSerialNumberabdBol = (
  purchaseOrderId: string,
  manufacturerSerialNumber: string | null,
  billOfLading: string | null,
  poReceived: any,
  index: number,
  partialReceivedPOs: UpdatedPOInformation[],
  poType = ''
  // eslint-disable-next-line sonarjs/cognitive-complexity
): UpdatedPOInformation[] => {
  const hasPOLineItemReceivedId =
    !!poReceived?.receivedLineItemDetails?.[0].poLineItemReceivedId;
  const partialPO = poExistInUpdatedList(
    hasPOLineItemReceivedId,
    poReceived,
    index,
    partialReceivedPOs
  );

  if (partialPO) {
    const otherPartialPos = partialReceivedPOs?.filter(
      // eslint-disable-next-line sonarjs/no-identical-functions
      (item) =>
        (hasPOLineItemReceivedId &&
          item.poLineItemReceivedId !==
            poReceived?.receivedLineItemDetails?.[0].poLineItemReceivedId) ||
        (!hasPOLineItemReceivedId &&
          item.purchaseOrderDetailId === poReceived?.purchaseOrderDetailId &&
          index !== item.purchaseOrderDetailIndex) ||
        item.purchaseOrderDetailId !== poReceived?.purchaseOrderDetailId
    );

    const updatedPartialPO = { ...partialPO };

    if (manufacturerSerialNumber !== null) {
      updatedPartialPO.manufacturerSerialNumber =
        manufacturerSerialNumber.trim();
    } else if (poReceived?.manufacturerSerialNumber) {
      updatedPartialPO.manufacturerSerialNumber =
        poReceived?.manufacturerSerialNumber;
    }

    if (billOfLading !== null) {
      updatedPartialPO.billOfLading = billOfLading.trim();
    }

    return [...otherPartialPos, { ...updatedPartialPO, poType: poType }];
  } else {
    const partial: UpdatedPOInformation = {
      purchaseOrderId,
      purchaseOrderDetailId: poReceived?.purchaseOrderDetailId,
      purchaseOrderDetailIndex: index,
      purchaseOrderDetailReceivedId: poReceived?.purchaseOrderDetailReceivedId,
      poLineItemReceivedId:
        poReceived?.receivedLineItemDetails?.[0].poLineItemReceivedId,
      billOfLading,
    };

    if (manufacturerSerialNumber !== null) {
      partial.manufacturerSerialNumber = manufacturerSerialNumber.trim();
    } else if (poReceived?.manufacturerSerialNumber) {
      partial.manufacturerSerialNumber = poReceived?.manufacturerSerialNumber;
    }

    if (billOfLading !== null) {
      partial.billOfLading = billOfLading.trim();
    }

    partial.poType = poType;

    return [...partialReceivedPOs, partial];
  }
};

export interface POListProps {
  expanded: boolean;
}

export const RegularPOList = ({ expanded }: POListProps) => {
  const initialCallToken = useRef<CancelTokenSource>();
  const { user } = useUserStateContext();
  const [hasApiError, setApiHasError] = useState(false);
  const [purchaseOrders, setPurchaseOrders] = useState<PurchaseOrder[]>([]);
  const [selectedPO, setSelectedPO] = useState('');
  const [selectedReceivedDetailPO, setSelectedReceivedDetailPO] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState<number>(INITIAL_OFFSET);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [partialReceivedPOs, setPartialReceivedPOs] = useState<
    UpdatedPOInformation[]
  >([]);
  const [vendorName, setVendorName] = useState('');
  const [totalCost, setTotalCost] = useState('');
  const [eta, setEta] = useState('');
  const { setSearchedPOInSection, setRegularPOLoading, setSearchItemFound } =
    useSearchActions();
  const { filter, manualPOReceive, reloadData } = useSearchDetails();
  const { poNumber, toDate, fromDate } = filter || {};
  const classes = useClasses();
  const isPurchaseOrdersEmpty = !purchaseOrders || purchaseOrders?.length === 0;
  const { dispatch } = useGlobalContext();

  const renderTableHead = () => (
    <>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth73),
        }}
      />
      <RACTableCell
        classes={{
          root: clsx(
            classes.tableHeader,
            classes.childTableCellWidth100,
            classes.minWidth100
          ),
        }}
      >
        PO #
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(
            classes.tableHeader,
            classes.childTableCellWidth100,
            classes.minWidth100
          ),
        }}
      >
        Created
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Item #
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Model #
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth250),
        }}
      >
        Description
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Brand
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth100),
        }}
      >
        Cost
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Receiving Now
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Serial #
      </RACTableCell>
      <RACTableCell
        classes={{
          root: clsx(classes.tableHeader, classes.childTableCellWidth150),
        }}
      >
        Bol
      </RACTableCell>
    </>
  );

  const renderTableContent = () => {
    if (isPurchaseOrdersEmpty) return <></>;

    return renderTableContentFilled();
  };

  const clearPOFields = () => {
    setVendorName('');
    setTotalCost('');
    setEta('');
    dispatch(setSelectedPOToReceive({} as PurchaseOrder));
  };

  const onPOSelection = (po: PurchaseOrder) => {
    const poNumber = String(po.purchaseOrderNumber);
    const clearPo = selectedPO === poNumber;
    clearPo ? setSelectedPO('') : setSelectedPO(poNumber);
    setPartialReceivedPOs([]);
    setSelectedReceivedDetailPO([]);
    if (clearPo) {
      clearPOFields();
    } else {
      po.vendorName && setVendorName(po.vendorName);
      setTotalCost(po.totalCost);
      po.estimatedDeliveryDate &&
        setEta(formatDateString(po.estimatedDeliveryDate));
      dispatch(setSelectedPOToReceive(po));
    }
    dispatch(setUpdatedPOData([] as UpdatedPOInformation[]));
  };

  const renderTableContentFilled = () => (
    <>
      {purchaseOrders?.map((po, index) => {
        const poNumber = String(po.purchaseOrderNumber);
        const showPODetail = poNumber === selectedPO && po.purchaseOrderDetails;

        return (
          <RACTableRow key={index} backgroundColor="white">
            <>
              <RACTableCell
                classes={{
                  root: clsx(
                    classes.paddingTopBottom8,
                    classes.childTableCellWidth100
                  ),
                }}
                colSpan={11}
              >
                <div className={classes.mainCellContainer}>
                  <RACCheckBox
                    id={String(po.purchaseOrderNumber)}
                    color="primary"
                    onChange={() => onPOSelection(po)}
                    checked={selectedPO === poNumber}
                    classes={{
                      checkbox: clsx(classes.checkbox, classes.mainCheckbox),
                    }}
                  />
                  <div className={classes.mainTableCellWrapper}>
                    <div
                      className={clsx(
                        classes.mainTableCellWidth100,
                        classes.minWidth100
                      )}
                    >
                      <span className={classes.mainTableCell}>
                        {getPONumber(po)}
                      </span>
                    </div>
                    <span>{`${formatDateString(po.createdDate)}`}</span>
                  </div>
                </div>
                {showPODetail && (
                  <RACTable
                    renderTableHead={() => <></>}
                    renderTableContent={() =>
                      renderChildTableContent(
                        po.purchaseOrderId,
                        generateFlatReceivedDetails(po.purchaseOrderDetails)
                      )
                    }
                  />
                )}
              </RACTableCell>
            </>
          </RACTableRow>
        );
      })}
    </>
  );

  const renderChildTableContent = (
    purchaseOrderId: string,
    poReceivedDetails: PurchaseOrderDetail[]
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => (
    <>
      {poReceivedDetails
        ?.flatMap((i) => [i, i])
        .map((poReceived: any, index) => {
          const receivedId = `${purchaseOrderId}-${
            poReceived?.purchaseOrderDetailId
          }-${poReceived?.purchaseOrderDetailReceivedId || index}`;
          const previousReceivedIdRow = `${purchaseOrderId}-${
            poReceived?.purchaseOrderDetailId
          }-${poReceived?.purchaseOrderDetailReceivedId || index - 1}`;
          const isPartial = !!poReceived?.partialReasonId;
          const isItemReceived = !!poReceived?.purchaseOrderDetailReceivedId;
          const isPreviouslyReceived =
            isItemReceived && !poReceived?.partialReasonId;
          const isSelected = selectedReceivedDetailPO.includes(receivedId);
          const hasPOLineItemReceivedId =
            !!poReceived?.receivedLineItemDetails?.[0].poLineItemReceivedId;
          const poInUpdatedPOList = poExistInUpdatedList(
            hasPOLineItemReceivedId,
            poReceived,
            index,
            partialReceivedPOs
          );

          if (index % 2 !== 0) {
            return (
              <ReceivedDetailRow
                key={index}
                index={index - 1}
                partialReceivedPO={partialReceivedPOs}
                poReceivedDetail={poReceived}
                isDisabled={
                  !selectedReceivedDetailPO.includes(previousReceivedIdRow)
                }
                isPartial={isPartial}
                isPreviouslyReceived={isPreviouslyReceived}
              />
            );
          }

          return (
            <RACTableRow key={index} backgroundColor="white">
              <>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.checkbox,
                      classes.childTableCellWidth100
                    ),
                  }}
                >
                  <RACCheckBox
                    id={receivedId}
                    color="primary"
                    onChange={() =>
                      isSelected
                        ? setSelectedReceivedDetailPO(
                            selectedReceivedDetailPO.filter(
                              (item) => item !== receivedId
                            )
                          )
                        : setSelectedReceivedDetailPO([
                            ...selectedReceivedDetailPO,
                            receivedId,
                          ])
                    }
                    checked={isSelected || (isPreviouslyReceived && !isPartial)}
                    classes={{ checkbox: classes.checkbox }}
                    disabled={isPreviouslyReceived && !isPartial}
                  />
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth170,
                      !isSelected && classes.disabled
                    ),
                  }}
                ></RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {poReceived?.inventoryNumber || ''}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {trimTextWithTooltip(poReceived?.modelNumber) || ''}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth250,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {trimTextWithTooltip(poReceived?.itemDescription) || ''}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {poReceived?.brand || ''}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth100,
                      !isSelected && classes.disabled
                    ),
                  }}
                >
                  {formatMoney(poReceived?.itemCost)}
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150
                    ),
                  }}
                >
                  <ReceiveNowActions
                    isDisabled={!isSelected}
                    isPartialReceivingNowSelected={isPartial}
                    onPartialRefusalSubmitted={(reason, comment, action) => {
                      const updatedPO = handlePartialRefusalSubmitted(
                        purchaseOrderId,
                        reason,
                        comment,
                        poReceived,
                        index,
                        action,
                        partialReceivedPOs
                      );
                      setPartialReceivedPOs(updatedPO);
                      dispatch(setUpdatedPOData(updatedPO));
                    }}
                  />
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150
                    ),
                  }}
                >
                  <RACTextField
                    name={`serial-${receivedId}`}
                    value={poReceived?.manufacturerSerialNumber || ''}
                    type="text"
                    inputProps={{ maxLength: 20 }}
                    onBlur={() =>
                      dispatch(setUpdatedPOData(partialReceivedPOs))
                    }
                    onChange={(value) =>
                      setPartialReceivedPOs(
                        addSerialNumberabdBol(
                          purchaseOrderId,
                          value,
                          null,
                          poReceived,
                          index,
                          partialReceivedPOs
                        )
                      )
                    }
                    disabled={!isSelected}
                  />
                </RACTableCell>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth150
                    ),
                  }}
                >
                  <RACTextField
                    name={`bol-${receivedId}`}
                    value={
                      poInUpdatedPOList
                        ? poInUpdatedPOList.billOfLading
                        : poReceived?.bol || ''
                    }
                    inputProps={{ maxLength: 20 }}
                    type="text"
                    onBlur={() =>
                      dispatch(setUpdatedPOData(partialReceivedPOs))
                    }
                    onChange={(value) =>
                      setPartialReceivedPOs(
                        addSerialNumberabdBol(
                          purchaseOrderId,
                          null,
                          value,
                          poReceived,
                          index,
                          partialReceivedPOs
                        )
                      )
                    }
                    disabled={!isSelected}
                  />
                </RACTableCell>
              </>
            </RACTableRow>
          );
        })}
    </>
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const fetchPurchaseOrders = (isFilterChanged = false) => {
    if (manualPOReceive) return;
    if (initialCallToken?.current) {
      initialCallToken.current?.cancel();
    }
    initialCallToken.current = getCancelTokenSource();

    setApiHasError(false);
    setLoading(true);
    const storeNumber = getSelectedStore();

    const request: GetPurchaseOrderRequest = {
      storeNumber: [storeNumber],
      purchaseOrderType: RegularPOTypes,
      poStatusType: RegularPOStatusTypes,
    };

    if (fromDate && toDate) {
      request.poDateRange = {
        endDate: toDate,
        startDate: fromDate,
      };
    }

    if (poNumber) {
      request.purchaseOrderNumber = poNumber;
      request.coworkerId = user?.employeeId;
    }

    getPurchaseOrders(
      request,
      offset,
      RECEIVING_PO_API_LIMIT,
      initialCallToken.current?.token
    )
      .then((response: GetPurchaseOrderResponse) => {
        const hasPO = !!response.purchaseOrders.length;
        if (hasPO) {
          if (
            isFilterChanged ||
            response?.purchaseOrders.length <= RECEIVING_PO_API_LIMIT
          ) {
            setPurchaseOrders(response.purchaseOrders);
          } else {
            setPurchaseOrders([...purchaseOrders, ...response.purchaseOrders]);
          }
          if (poNumber) {
            setSearchItemFound(true);
            setSearchedPOInSection(POSection.RegularPO);
          }
        }

        setHasMore(
          hasPO && response?.purchaseOrders.length === RECEIVING_PO_API_LIMIT
        );
        setLoading(false);
      })
      .catch((err) => {
        if (!err.__CANCEL__) {
          setApiHasError(true);
          setPurchaseOrders([]);
          setLoading(false);
        }
      });
  };

  const reload = () => {
    setOffset(INITIAL_OFFSET);
    setVendorName('');
    setTotalCost('');
    setEta('');
    setPartialReceivedPOs([]);
    setSelectedReceivedDetailPO([]);
    setSelectedPO('');
    dispatch(setSelectedPOToReceive({} as PurchaseOrder));
    dispatch(setUpdatedPOData([] as UpdatedPOInformation[]));
    setPurchaseOrders([]);
    fetchPurchaseOrders(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchPurchaseOrders, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchPurchaseOrders, [offset]);

  useEffect(() => {
    if (reloadData) {
      reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(reload, [poNumber, fromDate, toDate]);

  useEffect(() => {
    setRegularPOLoading(loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!expanded) {
      setVendorName('');
      setTotalCost('');
      setEta('');
      setPartialReceivedPOs([]);
      setSelectedReceivedDetailPO([]);
      setSelectedPO('');
      dispatch(setSelectedPOToReceive({} as PurchaseOrder));
      dispatch(setUpdatedPOData([] as UpdatedPOInformation[]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const handleRefresh = () => {
    setOffset(INITIAL_OFFSET);
    setVendorName('');
    setTotalCost('');
    setEta('');
    setPartialReceivedPOs([]);
    setSelectedReceivedDetailPO([]);
    setSelectedPO('');
    dispatch(setSelectedPOToReceive({} as PurchaseOrder));
    dispatch(setUpdatedPOData([] as UpdatedPOInformation[]));
    setPurchaseOrders([]);
    const isFilterSelected = !!poNumber || !!fromDate || !!toDate;
    fetchPurchaseOrders(isFilterSelected);
  };

  return (
    <div className={classes.purchaseOrderListWrapper}>
      <div
        className={classes.purchaseOrderList}
        data-testid={regularPOListTestId}
      >
        <div>
          <div className={classes.row}>
            <div className={classes.fieldWidth70percent}>
              <div className={classes.field}>
                <RACTextField
                  label="Vendor Name"
                  type="text"
                  name="vendorName"
                  value={vendorName}
                  disabled
                />
              </div>
              <div className={classes.field}>
                <FieldWithTooltip
                  id="regularPOTotalCost"
                  currency
                  title="Total Cost"
                >
                  <AutoNumeric
                    name="regularPOTotalCost"
                    value={totalCost}
                    disabled
                  />
                </FieldWithTooltip>
              </div>
              <div className={classes.field}>
                <RACTextField
                  label="ETA"
                  type="text"
                  name="eta"
                  value={eta}
                  disabled
                />
              </div>
            </div>
            <div className={clsx(classes.field, classes.fieldFlex)}>
              <Icon classes={{ root: classes.icon }} onClick={handleRefresh}>
                <FontAwesomeIcon icon={faSync} />
              </Icon>
            </div>
          </div>
          <div>
            <RACInfiniteScroll
              offset={offset}
              setOffset={setOffset}
              data={purchaseOrders}
              loading={loading}
              hasMore={hasMore}
              hasApiError={hasApiError}
              classes={{
                loader: classes.contentHeight,
                apiError: classes.contentHeight,
                noItems: classes.contentHeight,
              }}
              successContent={
                <RACTable
                  renderTableHead={renderTableHead}
                  renderTableContent={renderTableContent}
                  stickyHeader
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
