import { RACModal, RACButton, makeStyles } from '@rentacenter/racstrap';

import React from 'react';

export const noPOFoundModalTestId = 'noPOFoundModalTestId';

export interface ModalProps {
  open: boolean;
  onClose: () => void;
}

export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
  dialogRoot: {
    '& .MuiDialogContent-root': {
      margin: '1rem 0',
      padding: 0,
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: theme.typography.pxToRem(350),
      maxHeight: theme.typography.pxToRem(183),
    },
    '& .MuiTypography-h4': {
      fontSize: '20px !important',
    },
    '& .MuiInputBase-multiline': {
      margin: '8px 0px 0px 0px !important',
    },
  },
  dialogActions: {
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(15),
    justifyContent: 'center',
  },
}));

export const NoPOFoundModal = ({ open, onClose }: ModalProps) => {
  const classes = useStyles();

  return (
    <>
      {open && (
        <RACModal
          data-testid={noPOFoundModalTestId}
          isOpen={open}
          titleVariant="h4"
          classes={{
            dialogContent: classes.dialogContent,
            dialog: classes.dialogRoot,
            dialogActions: classes.dialogActions,
          }}
          maxWidth="sm"
          title=""
          content={<span>No result found</span>}
          onClose={onClose}
          buttons={
            <RACButton variant="contained" color="primary" onClick={onClose}>
              Ok
            </RACButton>
          }
        />
      )}
    </>
  );
};
