import React, { useState } from 'react';
import { ReceiveNowType } from '../../constants/constants';
import { RACRadio } from '@rentacenter/racstrap';
import { RefusalReasonModal } from './RefusalReasonModal';

export interface ReceiveNowActionsProps {
  isPartialReceivingNowSelected: boolean;
  isDisabled?: boolean;
  onPartialRefusalSubmitted: (
    reason: string,
    comment: string,
    action: string
  ) => void;
}

export const ReceiveNowActions = ({
  isPartialReceivingNowSelected,
  isDisabled,
  onPartialRefusalSubmitted,
}: ReceiveNowActionsProps) => {
  const [selectedItem, setSelectedItem] = useState(
    isPartialReceivingNowSelected ? ReceiveNowType.Partial : ReceiveNowType.All
  );
  const [openRefusalReasonModal, setOpenRefusalReasonModal] = useState(false);

  const handlePartialCancel = (pureclose: boolean) => {
    setOpenRefusalReasonModal(false);
    if (!pureclose) {
      setSelectedItem(ReceiveNowType.All);
    }
  };

  const handleOptionChange = (value: string) => {
    if (
      selectedItem === ReceiveNowType.All &&
      value === ReceiveNowType.Partial
    ) {
      setOpenRefusalReasonModal(true);
    } else {
      onPartialRefusalSubmitted('', '', ReceiveNowType.All);
    }
    setSelectedItem(value as ReceiveNowType);
  };

  return (
    <>
      <RACRadio
        name={ReceiveNowType.All}
        label={ReceiveNowType.All}
        value={ReceiveNowType.All}
        checked={selectedItem === ReceiveNowType.All}
        onChange={() => handleOptionChange(ReceiveNowType.All)}
        key={ReceiveNowType.All}
        disabled={isDisabled}
      />
      <RACRadio
        name={ReceiveNowType.Partial}
        label={ReceiveNowType.Partial}
        value={ReceiveNowType.Partial}
        checked={selectedItem === ReceiveNowType.Partial}
        onChange={() => handleOptionChange(ReceiveNowType.Partial)}
        key={ReceiveNowType.Partial}
        disabled={isDisabled}
      />
      {openRefusalReasonModal && (
        <RefusalReasonModal
          open={openRefusalReasonModal}
          onClose={handlePartialCancel}
          onSave={onPartialRefusalSubmitted}
        />
      )}
    </>
  );
};
