import { appConfig } from '../config/app-config';
import { ReferenceKeys, ReferenceResponse } from '../types/types';
import { client } from './client';

export const getReference = (
  referenceKey: ReferenceKeys
): Promise<ReferenceResponse[]> => {
  return client(
    `references/${referenceKey}`,
    { method: 'GET' },
    appConfig.apiUrls.am,
    true
  );
};
