import {
  RACTable,
  RACTableCell,
  RACTableRow,
  withStyles,
} from '@rentacenter/racstrap';
import clsx from 'clsx';
import React from 'react';
import { DAYS_OF_WEEK, ReceiveNowType } from '../../constants/constants';
import {
  PurchaseOrder,
  PurchaseOrderDetail,
  StoreDetail,
  User,
} from '../../types/types';
import { formatDate, formatMoney, getPONumber } from '../../utils/utils';
import {
  generateFlatReceivedDetails,
  ReceivedDetailRow,
} from './RegularPOList';
import logo from './logo.png';

const dataTestId = 'print-previously-received';

export interface ComponentToPrintProps {
  purchaseOrders: PurchaseOrder[];
  selectedPOId: string | null;
  classes: any;
  store?: StoreDetail;
  user?: User;
}

const useStyles = (theme: any) => ({
  purchaseOrderListWrapper: {
    width: '100%',
    height: theme.typography.pxToRem(284),
  },
  purchaseOrderList: {
    flexDirection: 'row',
    boxSizing: 'border-box',
    borderRadius: '1rem',
    overflowX: 'hidden',
  },
  paddingTopBottom8: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  mainTableCell: {
    fontWeight: 'bolder',
    fontSize: '1rem',
  },
  childTableCellWidth50: {
    width: theme.typography.pxToRem(50),
  },
  childTableCellWidth100: {
    width: theme.typography.pxToRem(100),
  },
  childTableCellWidth150: {
    width: theme.typography.pxToRem(150),
  },
  childTableCellWidth250: {
    width: theme.typography.pxToRem(250),
  },
  printPreviewHeaderWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '2px solid Gray',
    padding: theme.typography.pxToRem(10),
    ...theme.typography.body1,
    fontSize: theme.typography.pxToRem(8),
  },
  printPreviewHeaderCenterBlock: {
    alignSelf: 'center',
    fontSize: theme.typography.pxToRem(10),
  },
  printPreviewHeaderRightBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    alignSelf: 'center',
    marginTop: theme.typography.pxToRem(70),
  },
  printPreviewHeaderBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    height: theme.typography.pxToRem(60),
    width: theme.typography.pxToRem(60),
    marginBottom: '1rem',
  },
});

// This has to be class component, in order to integrate with react-to-print
class ComponentToPrint extends React.Component<ComponentToPrintProps> {
  renderTableHead = () => {
    const { classes } = this.props;
    return (
      <>
        <RACTableCell
          classes={{
            root: clsx(
              classes.paddingTopBottom8,
              classes.childTableCellWidth100
            ),
          }}
        >
          PO #
        </RACTableCell>
        <RACTableCell
          classes={{
            root: clsx(
              classes.paddingTopBottom8,
              classes.childTableCellWidth150
            ),
          }}
        >
          Item #
        </RACTableCell>
        <RACTableCell
          classes={{
            root: clsx(
              classes.paddingTopBottom8,
              classes.childTableCellWidth150
            ),
          }}
        >
          Model #
        </RACTableCell>
        <RACTableCell
          classes={{
            root: clsx(
              classes.paddingTopBottom8,
              classes.childTableCellWidth250
            ),
          }}
        >
          Description
        </RACTableCell>
        <RACTableCell
          classes={{
            root: clsx(
              classes.paddingTopBottom8,
              classes.childTableCellWidth150
            ),
          }}
        >
          Brand
        </RACTableCell>
        <RACTableCell
          classes={{
            root: clsx(
              classes.paddingTopBottom8,
              classes.childTableCellWidth100
            ),
          }}
        >
          Cost
        </RACTableCell>
        <RACTableCell
          classes={{
            root: clsx(
              classes.paddingTopBottom8,
              classes.childTableCellWidth150
            ),
          }}
        >
          Receiving Now
        </RACTableCell>
        <RACTableCell
          classes={{
            root: clsx(
              classes.paddingTopBottom8,
              classes.childTableCellWidth150
            ),
          }}
        >
          Serial #
        </RACTableCell>
      </>
    );
  };

  renderTableContent = () => {
    const { purchaseOrders, selectedPOId, classes } = this.props;
    let selectedPO: any;
    if (selectedPOId) {
      selectedPO = purchaseOrders.find(
        (po) => po.purchaseOrderNumber === selectedPOId
      ) as PurchaseOrder;
    }
    if (selectedPO) {
      return (
        <RACTableRow backgroundColor="white">
          <>
            <RACTableCell
              classes={{
                root: clsx(
                  classes.paddingTopBottom8,
                  classes.childTableCellWidth100
                ),
              }}
              colSpan={9}
            >
              <div>
                <span className={classes.mainTableCell}>
                  {getPONumber(selectedPO)}
                </span>
              </div>
              <RACTable
                renderTableHead={() => <></>}
                renderTableContent={() =>
                  this.renderChildTableContent(
                    generateFlatReceivedDetails(selectedPO.purchaseOrderDetails)
                  )
                }
              />
            </RACTableCell>
          </>
        </RACTableRow>
      );
    }
    return (
      <>
        {purchaseOrders?.map((po, index) => {
          return (
            <RACTableRow key={index} backgroundColor="white">
              <>
                <RACTableCell
                  classes={{
                    root: clsx(
                      classes.paddingTopBottom8,
                      classes.childTableCellWidth100
                    ),
                  }}
                  colSpan={9}
                >
                  <div>
                    <span className={classes.mainTableCell}>
                      {getPONumber(po)}
                    </span>
                  </div>
                </RACTableCell>
              </>
            </RACTableRow>
          );
        })}
      </>
    );
  };

  renderChildTableContent = (poReceivedDetails: PurchaseOrderDetail[]) => {
    const { classes } = this.props;
    return (
      <>
        {poReceivedDetails
          ?.flatMap((i) => [i, i])
          .map((poReceived: any, index) => {
            const isPreviouslyReceived =
              poReceived?.quantityOrdered === poReceived?.receivedCount;
            const isPartial = !!poReceived?.partialReasonId;

            if (index % 2 !== 0) {
              return (
                <ReceivedDetailRow
                  key={index}
                  index={index - 1}
                  partialReceivedPO={[]}
                  poReceivedDetail={poReceived}
                  isDisabled
                  isPartial={isPartial}
                  isPreviouslyReceived={isPreviouslyReceived}
                  dateReceivedColumnNeeded
                />
              );
            }

            return (
              <RACTableRow key={index} backgroundColor="white">
                <>
                  <RACTableCell
                    classes={{
                      root: clsx(
                        classes.paddingTopBottom8,
                        classes.childTableCellWidth50
                      ),
                    }}
                  ></RACTableCell>
                  <RACTableCell
                    classes={{
                      root: clsx(
                        classes.paddingTopBottom8,
                        classes.childTableCellWidth150
                      ),
                    }}
                  >
                    {poReceived?.inventoryNumber || ''}
                  </RACTableCell>
                  <RACTableCell
                    classes={{
                      root: clsx(
                        classes.paddingTopBottom8,
                        classes.childTableCellWidth150
                      ),
                    }}
                  >
                    {poReceived?.modelNumber || ''}
                  </RACTableCell>
                  <RACTableCell
                    classes={{
                      root: clsx(
                        classes.paddingTopBottom8,
                        classes.childTableCellWidth250
                      ),
                    }}
                  >
                    {poReceived?.itemDescription || ''}
                  </RACTableCell>
                  <RACTableCell
                    classes={{
                      root: clsx(
                        classes.paddingTopBottom8,
                        classes.childTableCellWidth150
                      ),
                    }}
                  >
                    {poReceived?.brand || ''}
                  </RACTableCell>
                  <RACTableCell
                    classes={{
                      root: clsx(
                        classes.paddingTopBottom8,
                        classes.childTableCellWidth100
                      ),
                    }}
                  >
                    {formatMoney(poReceived?.itemCost)}
                  </RACTableCell>
                  <RACTableCell
                    classes={{
                      root: clsx(
                        classes.paddingTopBottom8,
                        classes.childTableCellWidth150
                      ),
                    }}
                  >
                    {isPartial ? ReceiveNowType.Partial : ReceiveNowType.All}
                  </RACTableCell>
                  <RACTableCell
                    classes={{
                      root: clsx(
                        classes.paddingTopBottom8,
                        classes.childTableCellWidth150
                      ),
                    }}
                  >
                    {poReceived?.manufacturerSerialNumber || ''}
                  </RACTableCell>
                </>
              </RACTableRow>
            );
          })}
      </>
    );
  };
  // eslint-disable-next-line sonarjs/cognitive-complexity
  render() {
    const { classes, store, user } = this.props;
    const today = new Date();
    const storeAddress = store
      ? `${store.addressLine1}, ${store.city}, ${store.stateName}, ${store.zip}`
      : '';

    return (
      <div className={classes.purchaseOrderListWrapper}>
        <div className={classes.printPreviewHeaderWrapper}>
          <div className={classes.printPreviewHeaderBlock}>
            <img src={logo} className={classes.logo} alt="logoImage" />
            <span>Rent-A-Center. Inc.</span>
            <span>{`${store?.storeNumber} ${store?.storeName}`}</span>
            <span>{storeAddress}</span>
          </div>
          <div className={classes.printPreviewHeaderCenterBlock}>
            <span>Previously Received PO</span>
          </div>
          <div className={classes.printPreviewHeaderRightBlock}>
            <span>{`${DAYS_OF_WEEK[today.getDay()]} ${formatDate(
              today
            )}`}</span>
            <span>
              Report requested by {`${user?.lastName}, ${user?.firstName}`}
            </span>
          </div>
        </div>
        <div className={classes.purchaseOrderList} data-testid={dataTestId}>
          <RACTable
            renderTableHead={this.renderTableHead}
            renderTableContent={this.renderTableContent}
            stickyHeader
          />
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default withStyles(useStyles)(ComponentToPrint);
