import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { StyleClasses } from '../../types/types';
import {
  ApiStateWrapper,
  ApiStateWrapperClassKey,
  Loader,
} from '../ApiStateWrapper/ApiStateWrapper';

export const footerTestId = 'footerTestId';

const CONTAINER_HEIGHT = 233;
const DEFAULT_DATA_FETCH_LIMIT = 15;

interface RACInfiniteScrollProps {
  offset: number;
  setOffset: (offset: number) => void;
  defaultDataFetchLimit?: number;
  data: any;
  hasMore: boolean;
  loading: boolean;
  containerHeight?: number;
  hasApiError: boolean;
  successContent: JSX.Element;
  classes?: StyleClasses<ApiStateWrapperClassKey>;
}

export const RACInfiniteScroll = ({
  offset,
  setOffset,
  defaultDataFetchLimit = DEFAULT_DATA_FETCH_LIMIT,
  data,
  loading,
  hasMore,
  containerHeight = CONTAINER_HEIGHT,
  hasApiError,
  successContent,
  classes,
}: RACInfiniteScrollProps) => {
  const fetchNextPage = () => {
    if (data?.length < 1) return;
    setOffset(offset + defaultDataFetchLimit);
  };

  return (
    <InfiniteScroll
      next={fetchNextPage}
      dataLength={data?.length || 0}
      hasMore={hasMore}
      height={containerHeight}
      loader={loading && data?.length ? <Loader /> : null}
    >
      <ApiStateWrapper
        loading={loading && data.length === 0}
        hasApiError={hasApiError && data.length === 0}
        response={data}
        successContent={successContent}
        classes={classes}
      />
    </InfiniteScroll>
  );
};
