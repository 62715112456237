import { CancelToken } from 'axios';
import { appConfig } from '../config/app-config';
import { StoreDetailResponse } from '../types/types';
import { client } from './client';

export const getStoreDetailByNumber = (
  storeNumer: string,
  cancelToken?: CancelToken
): Promise<StoreDetailResponse> => {
  return client(
    `stores/${storeNumer}/details`,
    { method: 'GET', cancelToken },
    appConfig.apiUrls.am,
    true
  );
};
