import React, { useEffect, useState } from 'react';
import { Search } from '../Search/Search';
import { ReceivingPOHelpLink } from './ReceivingPOHelpLink';
import { ManualPOSection } from './ManualPOSection';
import { RegularPOSection } from './RegularPOSection';
import { PreviouslyReceivedPOSection } from './PreviouslyReceivedPOSection';
import { Footer } from '../../common/Footer/Footer';
import { ReceivingPOFooter } from './ReceivingPOFooter';
import { POSection } from '../../constants/constants';
import PrintPreviouslyReceivedModal from './PrintPreviouslyReceivedModal';
import { useGlobalContext } from '../../context/global/GlobalContext';
import { useUserStateContext } from '../../context/user/user-contexts';
import { useStoreDetails } from '../../context/Store/StoreProvider';
import { LoadingOverlay } from '../../common/LoadingOverlay/LoadingOverlay';
import {
  useSearchActions,
  useSearchDetails,
} from '../../context/Search/SearchProvider';
import { NoPOFoundModal } from './NoPOFoundModal';
export interface SectionProps {
  selected: string;
  onChange: (
    section: string
  ) => (event: React.ChangeEvent<unknown>, expanded: boolean) => void;
}

export const ReceivingPO = () => {
  const [selectedSection, setSelectedSection] = useState(POSection.RegularPO);
  const [isPOReceivePrintOpen, setIsPOReceivePrintOpen] = useState(false);
  const { user } = useUserStateContext();
  const { store } = useStoreDetails();

  const {
    previouslyReceived: { data: purchaseOrders, selectedItemId: selectedPOId },
  } = useGlobalContext();
  const { searchedPOInSection, showLoader, filter, noSearchItemFound } =
    useSearchDetails();
  const { setNoSearchItemFound, setSearchedPOInSection } = useSearchActions();
  const { poNumber } = filter;

  const handleSectionChange = (section: string) => () => {
    setSelectedSection(section as POSection);
  };

  const onPrint = () => {
    setIsPOReceivePrintOpen(true);
  };

  useEffect(() => {
    searchedPOInSection && setSelectedSection(searchedPOInSection as POSection);
  }, [searchedPOInSection]);

  useEffect(() => {
    selectedSection && setSearchedPOInSection('' as POSection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection]);

  useEffect(() => {
    if (poNumber) {
      setSelectedSection('' as POSection);
    } else {
      setSelectedSection(POSection.RegularPO);
    }
  }, [poNumber]);

  useEffect(() => {
    const isPORev = location?.search === '?rev';
    if (isPORev) {
      setSelectedSection(POSection.PreviouslyReceivedPO);
    }
  }, []);

  return (
    <>
      {isPOReceivePrintOpen && (
        <PrintPreviouslyReceivedModal
          onClose={() => setIsPOReceivePrintOpen(false)}
          purchaseOrders={purchaseOrders}
          selectedPOId={selectedPOId}
          user={user}
          store={store}
        />
      )}
      {noSearchItemFound && (
        <NoPOFoundModal open onClose={() => setNoSearchItemFound(false)} />
      )}
      {showLoader && <LoadingOverlay />}
      <ReceivingPOHelpLink />
      <Search />
      <RegularPOSection
        selected={selectedSection}
        onChange={handleSectionChange}
      />
      <ManualPOSection
        selected={selectedSection}
        onChange={handleSectionChange}
      />
      <PreviouslyReceivedPOSection
        selected={selectedSection}
        onChange={handleSectionChange}
      />
      <Footer>
        <ReceivingPOFooter
          onPrint={onPrint}
          isPrintDisabled={!purchaseOrders?.length}
        />
      </Footer>
    </>
  );
};
