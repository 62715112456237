import { RACModal, RACButton, makeStyles } from '@rentacenter/racstrap';

import React, { useState, useEffect } from 'react';

export const alertModalTestId = 'alertModalTestId';

interface ModalProps {
  open: boolean;
  onClose?: () => void;
  onOkClick?: () => void;
  message: string;
  title?: string;
}

export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    textAlign: 'center',
    fontWeight: 'bold',
    height: theme.typography.pxToRem(175),
  },
  dialogRoot: {
    '& .MuiDialogContent-root': {
      padding: '1rem',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: theme.typography.pxToRem(500),
      maxHeight: theme.typography.pxToRem(175),
    },
    '& .MuiTypography-h4': {
      fontSize: '20px !important',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: theme.typography.pxToRem(10),
    },
    '& .MuiInputBase-multiline': {
      margin: '8px 0px 0px 0px !important',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'OpenSans-regular',
      fontSize: theme.typography.pxToRem(14),
    },
  },
  dialogActions: {
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(15),
    justifyContent: 'center',
  },
}));

export const AlertModal = ({
  open,
  onClose,
  onOkClick,
  message,
  title,
}: ModalProps) => {
  const classes = useStyles();
  const [showAlert, setShowAlert] = useState(open);

  const handleClose = () => (onClose ? onClose() : setShowAlert(false));

  useEffect(() => setShowAlert(open), [open]);

  return (
    <>
      {showAlert && (
        <RACModal
          data-testid={alertModalTestId}
          isOpen={showAlert}
          titleVariant="h4"
          classes={{
            dialogContent: classes.dialogContent,
            dialog: classes.dialogRoot,
            dialogActions: classes.dialogActions,
          }}
          maxWidth="sm"
          title={title || ''}
          content={<span>{message}</span>}
          onClose={handleClose}
          buttons={
            <RACButton
              variant="contained"
              color="primary"
              onClick={() => (onOkClick ? onOkClick() : handleClose())}
            >
              Ok
            </RACButton>
          }
        />
      )}
    </>
  );
};
