import { CancelTokenSource } from 'axios';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getCancelTokenSource } from '../../api/client';
import { getStoreDetailByNumber } from '../../api/store';
import { StoreDetail } from '../../types/types';
import { getSelectedStore } from '../../utils/utils';

export interface StoreState {
  store?: StoreDetail;
  loading: boolean;
  hasApiError: boolean;
}

export interface StoreDispatchState {
  fetchStoreDetailsByNumber: (
    storeNumber: string,
    cancelToken: CancelTokenSource
  ) => Promise<void | StoreDetail[]>;
}

export const StoreStateContext = createContext<StoreState>({} as StoreState);
export const StoreDispatchContext = createContext<StoreDispatchState>(
  {} as StoreDispatchState
);

export const StoreProvider = (props: { children: ReactNode }) => {
  const [store, setStore] = useState<StoreDetail>();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasApiError, setHasApiError] = useState(false);

  useEffect(() => {
    const cancelToken: CancelTokenSource = getCancelTokenSource();
    fetchStoreDetailsByNumber(getSelectedStore(), cancelToken);
    return () => {
      cancelToken.cancel();
    };
  }, []);

  const fetchStoreDetailsByNumber = async (
    storeNumber: string,
    cancelToken: CancelTokenSource
  ) => {
    setHasApiError(false);
    setLoading(true);
    getStoreDetailByNumber(storeNumber, cancelToken.token)
      .then(
        (response) =>
          response?.response?.length && setStore(response.response[0])
      )
      .catch(() => setHasApiError(true))
      .finally(() => setLoading(false));
  };

  return (
    <StoreStateContext.Provider
      value={{
        store,
        loading,
        hasApiError,
      }}
    >
      <StoreDispatchContext.Provider
        value={{
          fetchStoreDetailsByNumber,
        }}
      >
        {props.children}
      </StoreDispatchContext.Provider>
    </StoreStateContext.Provider>
  );
};

export const useStoreDetails = () => useContext(StoreStateContext);

export const useStoreActions = () => useContext(StoreDispatchContext);
