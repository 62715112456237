// import { UpdatedPOInformation } from '../../../components/ReceivingPO/RegularPOList';
import { PurchaseOrder } from '../../../types/types';
import { Action } from '../../../types/types';

export enum SelectedPOTRevActionType {
  SET_SELECTED_PO_TO_REVERSE = 'SET_SELECTED_PO_TO_REVERSE',
}

export interface SelectedPORevAction extends Action {
  readonly type: any;
  readonly payload: any;
}

export const setSelectedPORev = (
  purchaseOrder: PurchaseOrder
): SelectedPORevAction => ({
  type: SelectedPOTRevActionType.SET_SELECTED_PO_TO_REVERSE,
  payload: purchaseOrder,
});
