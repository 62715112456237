// import { UpdatedPOInformation } from '../../../components/ReceivingPO/RegularPOList';
import { PurchaseOrder, UpdatedPOInformation } from '../../../types/types';
import { Action } from '../../../types/types';

export enum SelectedPOToReceiveActionType {
  SET_SELECTED_PO_TO_RECEIVE = 'SET_SELECTED_PO_TO_RECEIVE',
  SET_UPDATED_PO_DATA = 'SET_UPDATED_PO_DATA',
}

export interface SelectedPOToReceiveAction extends Action {
  readonly type: any;
  readonly payload: any;
}

export const setSelectedPOToReceive = (
  purchaseOrder: PurchaseOrder
): SelectedPOToReceiveAction => ({
  type: SelectedPOToReceiveActionType.SET_SELECTED_PO_TO_RECEIVE,
  payload: purchaseOrder,
});

export const setUpdatedPOData = (
  updatedPO: UpdatedPOInformation[]
): SelectedPOToReceiveAction => ({
  type: SelectedPOToReceiveActionType.SET_UPDATED_PO_DATA,
  payload: updatedPO,
});
