import {
  RACModal,
  RACButton,
  makeStyles,
  Typography,
} from '@rentacenter/racstrap';

import React, { useState } from 'react';

export const useStyles = makeStyles((theme: any) => ({
  titleAndHelpContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    ...theme.typography.h4,
    fontSize: '1.5rem !important',
    fontFamily: 'OpenSans-bold',
    marginTop: '.5rem',
    marginBottom: '.5rem',
  },
  helpButton: {
    textDecoration: 'underline',
    padding: '0px',
  },
  dialogContent: {
    textAlign: 'left',
    height: theme.typography.pxToRem(400),
    fontSize: theme.typography.pxToRem(16),
  },
  dialogRoot: {
    '& .MuiDialogContent-root': {
      justifyContent: 'center',
      display: 'flex',
      marginBottom: theme.typography.pxToRem(20),
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: theme.typography.pxToRem(400),
      maxHeight: theme.typography.pxToRem(200),
      borderRadius: '1rem',
    },
    '& .MuiTypography-h4': {
      fontSize: '20px !important',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: theme.typography.pxToRem(10),
    },
    '& .MuiInputBase-multiline': {
      margin: '8px 0px 0px 0px !important',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'OpenSans-regular',
      fontSize: theme.typography.pxToRem(14),
    },
  },
  dialogActions: {
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(15),
    justifyContent: 'center',
  },
  contentPhoneNumber: {
    fontWeight: 'bold',
  },
}));

export const ReceivingPOHelpLink = () => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);

  const handleModalClose = () => setOpenModal(false);

  return (
    <div className={classes.titleAndHelpContainer}>
      <Typography className={classes.title} variant="h4">
        Purchase Order
      </Typography>
      <RACButton
        variant="text"
        color="primary"
        onClick={() => setOpenModal(true)}
        className={classes.helpButton}
      >
        Purchase Order Help
      </RACButton>
      <RACModal
        isOpen={openModal}
        titleVariant="h4"
        classes={{
          dialogContent: classes.dialogContent,
          dialog: classes.dialogRoot,
          dialogActions: classes.dialogActions,
        }}
        maxWidth="sm"
        title="Need Help?"
        content={
          <div>
            <span>Contact RAC Solution Center :</span>
            <span className={classes.contentPhoneNumber}>(800) 275-2696</span>
          </div>
        }
        onClose={handleModalClose}
        buttons={
          <>
            <RACButton
              variant="contained"
              color="primary"
              onClick={handleModalClose}
            >
              Ok
            </RACButton>
          </>
        }
      />
    </div>
  );
};
