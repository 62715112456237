import { makeStyles, RACCOLOR, RACFieldError } from '@rentacenter/racstrap';
import React, { ReactChild } from 'react';

const useStyles = makeStyles((theme: any) => ({
  fieldWithTooltipWrapper: {
    position: 'relative',
  },
  currency: {
    width: '13%',
    padding: `${theme.typography.pxToRem(10)} ${theme.typography.pxToRem(14)}`,
    borderTopLeftRadius: theme.typography.pxToRem(6),
    borderBottomLeftRadius: theme.typography.pxToRem(6),
    zIndex: 1,

    fontSize: '0.875rem',
    position: 'absolute',
    top: theme.typography.pxToRem(1),
    left: theme.typography.pxToRem(1),
    background: 'rgb(233, 236, 239)',
  },
  required: {
    color: RACCOLOR.MVS_RED,
  },
  title: {
    position: 'relative',
    fontSize: '0.875rem',
    transform: 'scale(1) !important',
    fontFamily: 'OpenSans-semibold',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: 0,
  },
}));
export const FieldWithTooltip = (props: {
  children: ReactChild;
  id?: string;
  currency?: boolean;
  required?: boolean;
  title?: string;
  errorMessage?: string;
}) => {
  const styles = useStyles();
  return (
    <label htmlFor={props.id}>
      <span>
        {props.title && <span className={styles.title}> {props.title} </span>}
        {props.required && <span className={styles.required}>*</span>}
      </span>
      <div className={styles.fieldWithTooltipWrapper}>
        {props.currency && <span className={styles.currency}>$</span>}
        {props.children}
      </div>
      {props.errorMessage && (
        <RACFieldError errorMessage={props.errorMessage} />
      )}
    </label>
  );
};
