import React, { useReducer, ReactNode } from 'react';

import { globalReducer, initialState } from './globalReducer';
import { GlobalContext } from './GlobalContext';

export const GlobalContextProvider = (props: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <GlobalContext.Provider value={{ ...state, dispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
