import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { add, sub } from 'date-fns';
import { isEmpty } from 'lodash';
import {
  DATE_FORMAT,
  SELECT_ONE_OPTION,
  STORE_NUMBER_KEY,
} from '../constants/constants';
import {
  APIError,
  ListStatus,
  PurchaseOrder,
  ReferenceResponse,
} from '../types/types';

export const sanitizeURL = (url: string): string => {
  if (!url) {
    return '';
  }

  const badURLRegex = RegExp('^((https)|(http)):/{3,}');
  const isBadURL = badURLRegex.test(url);

  if (isBadURL) {
    return 'https://' + url.replace(badURLRegex, '');
  }

  return url;
};

export const getErrorMessage = (response?: AxiosResponse<APIError>) => {
  if (response?.status === 400 && response?.data.errors[0].code) {
    return response?.data.errors[0].message;
  }

  return null;
};
export const getSelectedStore = () =>
  sessionStorage.getItem(STORE_NUMBER_KEY) || '';

export interface ComponentStateProps {
  loading?: boolean;
  hasApiError?: boolean;
  response?: any;
}

export const getComponentState = ({
  loading,
  hasApiError,
  response,
}: ComponentStateProps): ListStatus => {
  if (loading) return 'loading';
  if (hasApiError) return 'apiError';

  if (response) {
    if (Array.isArray(response)) {
      return response.length > 0 ? 'success' : 'empty';
    } else if (isEmpty(response)) {
      return 'empty';
    }
    return 'success';
  } else {
    return 'initial';
  }
};

export const noOp = () => {
  // noop
};

export const orderByField = (fieldName: string, arrayToOrder: any[]) => {
  if (!arrayToOrder || !arrayToOrder.length || !fieldName) return arrayToOrder;
  return arrayToOrder.sort((a, b) => (a[fieldName] > b[fieldName] ? 1 : -1));
};

export const isNonEmptyValue = (value: string): boolean => {
  if (value?.length > 0) {
    return value.trim() !== '';
  }

  return true;
};

export const filterById = (items: any[], id: string) =>
  (items && items.filter((el: { id: string }) => el.id === id)) || [];

interface Element {
  name: string;
  id: string;
  [key: string]: any;
}
export const populateSelectByChild = (data: any[], child?: string) => {
  return (
    data &&
    data.map((element: Element) => {
      const { name, id } = element;

      return child
        ? {
            value: id,
            label: name || id,
            [child]: element[child],
          }
        : {
            value: id,
            label: name || id,
          };
    })
  );
};

export const roundUp = (number: number, decimals = 2) => {
  return Number(Math.ceil(Number(number + 'e' + decimals)) + 'e-' + decimals);
};

export const calculateValue = (calculate: any, ...moreArgs: string[]) => {
  const rest = moreArgs.map((value: string) => {
    return parseFloat(value) || 1;
  });

  const output: number = calculate(...rest);

  return roundUp(output).toString();
};

export const formatMoney = (amount: string | number) => {
  if (amount === null || amount === undefined || isNaN(amount as number)) {
    return '$0.00';
  }

  return `$${Number(amount).toFixed(2)}`;
};

export const formatPhoneNumber = (phone: string) => {
  if (!phone || phone.includes('-')) return phone;

  return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6)}`;
};

export const getPONumber = (po: PurchaseOrder) => {
  if (po.poType === 'ER') return po.externalPoNumber || '';

  return po.purchaseOrderNumber || '';
};

export const pipe =
  (...fns: any) =>
  (x: any) =>
    fns.reduce((v: any, f: (value: any) => void) => f(v), x);

export const mapReferenceResponse = (references: ReferenceResponse[]) => {
  if (!references || !references.length) return references;
  return references.map((reference) => ({
    label: reference.description,
    value: reference.referenceCode,
    ...reference,
  }));
};

export const addSelectOneOption = (arrayWithOptions: any[]) => {
  if (!arrayWithOptions) {
    return [
      {
        label: SELECT_ONE_OPTION,
        value: '',
      },
    ];
  }

  return [
    {
      label: SELECT_ONE_OPTION,
      value: '',
    },
    ...arrayWithOptions,
  ];
};

// Dates are stored with local timezone of the respective store.
// We want to show the same data, by ignoring the user's local date settings
// e.g. 2021-07-19T16:07:42 or 2021-07-20
// yyyy-mm-dd
export const formatDateString = (dateAsString: string | undefined): string => {
  if (!dateAsString || typeof dateAsString !== 'string') {
    return '';
  }
  let dateWithoutHours;
  if (dateAsString.includes('T')) {
    dateWithoutHours = dateAsString.split('T')[0];
  } else {
    dateWithoutHours = dateAsString;
  }

  // [0] = 'yyyy'; [1] = 'mm'; [2] = 'dd'
  let dateAsArray = [] as string[];
  if (dateWithoutHours.includes('-')) {
    dateAsArray = dateWithoutHours.split('-');
  } else if (dateWithoutHours.includes('/')) {
    dateAsArray = dateWithoutHours.split('/');
  }

  if (dateAsArray?.length !== 3) {
    return '';
  }

  // mm/dd/yyyy
  return `${dateAsArray[1]}/${dateAsArray[2]}/${dateAsArray[0]}`;
};

export function isValidDate(d: any) {
  return d instanceof Date && !isNaN(d.getTime());
}

export const formatDate = (date: Date, dateFormat = DATE_FORMAT): string => {
  if (isValidDate(date)) return format(date, dateFormat);
  return '';
};
export const TWELVE_MONTHS_AGO = sub(new Date(), { months: 12 });
export const TWELVE_MONTHS_AFTER = add(new Date(), { months: 12 });

export const ONE_MONTHS_AGO_FROM = (date: string) =>
  sub(new Date(date), { months: 1 });
export const ONE_MONTHS_AFTER_FROM = (date: string) =>
  add(new Date(date), { months: 1 });

export const ONE_DAY_AGO_FROM = (date: string) =>
  sub(new Date(date), { days: 1 });
export const ONE_DAY_AFTER_FROM = (date: string) =>
  add(new Date(date), { days: 1 });
