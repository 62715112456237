import {
  RACModal,
  RACButton,
  makeStyles,
  RACSelect,
  TextField,
} from '@rentacenter/racstrap';

import React, { useEffect, useState } from 'react';
import { getReference } from '../../api/reference';
import { API_ERROR_MESSAGE, ReceiveNowType } from '../../constants/constants';
import { ReferenceKeys, Option } from '../../types/types';
import {
  addSelectOneOption,
  mapReferenceResponse,
  orderByField,
  pipe,
} from '../../utils/utils';

const ORDER_BY_DESC_FIELD = 'description';
export const orderByDescField = orderByField.bind(null, ORDER_BY_DESC_FIELD);
export const refusalReasonModalTestId = 'refusalReasonModalTestId';
export interface ModalProps {
  open: boolean;
  onClose: (pureclose: boolean) => void;
  onSave: (reason: string, comment: string, action: string) => void;
}

export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    textAlign: 'left',
    height: theme.typography.pxToRem(400),
  },
  dialogRoot: {
    '& .MuiDialogContent-root': {
      padding: '1rem',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: theme.typography.pxToRem(500),
      maxHeight: theme.typography.pxToRem(400),
    },
    '& .MuiTypography-h4': {
      fontSize: '20px !important',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: theme.typography.pxToRem(10),
    },
    '& .MuiInputBase-multiline': {
      margin: '8px 0px 0px 0px !important',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'OpenSans-regular',
      fontSize: theme.typography.pxToRem(14),
    },
  },
  reasonDescLabel: {
    marginTop: theme.typography.pxToRem(22),
    fontWeight: 600,
  },
  commentsLabel: {
    color: theme.palette.text.primary,
    transform: 'scale(1) !important',
    ...theme.typography.body1,
    position: 'relative',
    display: 'block',
  },
  dialogActions: {
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(15),
  },
  commentsWrapper: {
    display: 'inline-flex',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.typography.pxToRem(24),
    marginBotton: theme.typography.pxToRem(16),
  },
  reasonWrapper: {
    width: '75%',
  },
  reasonOptionsPaper: {
    maxHeight: theme.typography.pxToRem(150),
  },
}));

export interface RefusalReasonModalContentProps {
  reason: string;
  reasonOptions: Option[];
  comment: string;
  setReason: (reason: string) => void;
  setComment: (comment: string) => void;
  apiError: boolean;
  isLoading: boolean;
}
const RefusalReasonModalContent = ({
  reason,
  comment,
  setReason,
  setComment,
  apiError,
  isLoading,
  reasonOptions,
}: RefusalReasonModalContentProps) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.reasonWrapper}>
        <RACSelect
          classes={{ paper: classes.reasonOptionsPaper }}
          inputLabel="Reason"
          defaultValue={reason}
          loading={isLoading && !reason.length}
          options={reasonOptions}
          onChange={(e: React.ChangeEvent<{ value: any }>) =>
            setReason(e.target.value)
          }
          {...(apiError && {
            errorMessage: API_ERROR_MESSAGE,
          })}
        />
      </div>
      <div className={classes.reasonDescLabel}>
        Describe which parts of the set are damaged or missing for this item.
      </div>
      <div className={classes.commentsWrapper}>
        <label className={classes.commentsLabel}>Comments</label>
        <TextField
          multiline
          variant="outlined"
          value={comment}
          key="refusalComment"
          maxRows={3}
          inputProps={{ maxLength: 50 }}
          minRows={3}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        />
      </div>
    </>
  );
};

export const RefusalReasonModal = ({ open, onSave, onClose }: ModalProps) => {
  const classes = useStyles();
  const [reason, setReason] = useState('');
  const [reasonOptions, setReasonOptions] = useState<Option[]>([]);
  const [comment, setComment] = useState('');
  const [apiError, setApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isSaveDisabled = !reason || !comment;

  const localReasonOptions = localStorage.getItem(
    ReferenceKeys.PARTIAL_PO_REASON_TYPE
  );

  const handleSave = () => {
    onSave(reason, comment, ReceiveNowType.Partial);
    onClose(true);
  };

  useEffect(() => {
    if (reasonOptions.length && !localReasonOptions) {
      localStorage.setItem(
        ReferenceKeys.PARTIAL_PO_REASON_TYPE,
        JSON.stringify(reasonOptions)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasonOptions]);

  useEffect(() => {
    if (localReasonOptions) {
      setReasonOptions(JSON.parse(localReasonOptions));
      return;
    }
    setIsLoading(true);
    getReference(ReferenceKeys.PARTIAL_PO_REASON_TYPE)
      .then((response: any) =>
        pipe(
          orderByDescField,
          mapReferenceResponse,
          addSelectOneOption,
          setReasonOptions
        )(response)
      )
      .catch(() => {
        setApiError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {open && (
        <RACModal
          data-testid={refusalReasonModalTestId}
          isOpen={open}
          titleVariant="h4"
          classes={{
            dialogContent: classes.dialogContent,
            dialog: classes.dialogRoot,
            dialogActions: classes.dialogActions,
          }}
          maxWidth="sm"
          title="Refusal Reason"
          content={
            <RefusalReasonModalContent
              reason={reason}
              comment={comment}
              setReason={setReason}
              setComment={setComment}
              apiError={apiError}
              isLoading={isLoading}
              reasonOptions={reasonOptions}
            />
          }
          onClose={() => {
            setReason('');
            setComment('');
            onClose(false);
          }}
          buttons={
            <>
              <RACButton
                variant="outlined"
                color="secondary"
                onClick={() => onClose(false)}
              >
                Cancel
              </RACButton>
              <RACButton
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={isSaveDisabled}
              >
                Ok
              </RACButton>
            </>
          }
        />
      )}
    </>
  );
};
