import {
  PurchaseOrder,
  PreviouslyReceivedState,
  UpdatedPOInformation,
} from '../../../types/types';
import { GlobalContextAction } from '../globalReducer';
import { PreviouslyReceivedActionType } from './previouslyReceivedActions';

export const previouslyReceivedReducer = (
  state: PreviouslyReceivedState,
  action: GlobalContextAction
): PreviouslyReceivedState => {
  switch (action.type) {
    case PreviouslyReceivedActionType.SET_PREVIOUSLY_RECEIVED:
      return {
        ...state,
        data: action.payload as PurchaseOrder[],
      };
    case PreviouslyReceivedActionType.SET_SELECTED_PREVIOUSLY_RECEIVED_ID:
      return {
        ...state,
        selectedItemId: action.payload as string,
      };
    case PreviouslyReceivedActionType.SET_UPDATED_ITEMS_DATA:
      return {
        ...state,
        updatedPO: action.payload as UpdatedPOInformation[],
      };

    default:
      return {
        ...state,
      };
  }
};
