import { createContext, Dispatch, useContext } from 'react';
import { Action } from '../../types/types';

import { GlobalState, initialState } from './globalReducer';

export interface GlobalContextValue extends GlobalState {
  dispatch: Dispatch<Action>;
}

export const GlobalContext = createContext<GlobalContextValue>({
  ...initialState,
  dispatch: () => {
    // noop
  },
});

export const useGlobalContext = (): GlobalContextValue =>
  useContext(GlobalContext);
