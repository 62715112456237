export const ProductType = 'RPDRECEIVINGPO';

export const COLUMN_TEXT_LIMIT = 30;

export enum ProductPermissions {
  ReceivingPO = 'ReceivingPO',
}
export const STORE_NUMBER_KEY = 'storeNumber';
export const RECEIVING_PO_API_LIMIT = 15;
export const INITIAL_OFFSET = 0;

export const SELECT_ONE_OPTION = '<Select One>';

export const API_ERROR_MESSAGE = 'Unable to fetch the data';
export const FULL_API_ERROR_MESSAGE =
  'Unable to fetch the data. Please try again later.';
export const REQUIRED_FIELD_MESSAGE = 'This field is required';
export const PO_RECEIVED_SUCCESS = 'Purchase order received successfully.';
export const PO_REVERSED_SUCCESS = 'Purchase order reversed successfully.';
export const PO_RECEIVED_ERROR =
  'Unable to receive purchase order. Please try again later.';
export const PO_REVERSE_ERROR =
  'Unable to reverse purchase order. Please try again later.';
export const PO_RECEIVED_ERROR_TRY_AGAIN = 'Receiving PO Failed';
export const SHORT_REQUIRED_FIELD_MESSAGE = 'Field required';

export enum POSearchFormField {
  PONumber = 'poNumber',
  VendorName = 'vendorName',
  BOLNumber = 'bolNumber',
  TotalCost = 'totalCost',
  ETA = 'eta',
}

export enum ReceiveNowType {
  All = 'All',
  Partial = 'Partial',
}

export enum POSearchFormLabel {
  PONumber = 'PO #',
  VendorName = 'Vendor Name',
  BOLNumber = 'BOL #',
  TotalCost = 'Total Cost',
  ETA = 'ETA',
  PreviouslyReceivedPO = 'Previously Received PO',
}

export interface GenericObject {
  [key: string]: any;
}

export const RegularPOTypes = [
  'DS', //	Direct To Store
  'EDN', //eCommerce drop-ship order
  'REG', //Regular
  'EO', //eCommerce Order
  'SDN', //Customer drop-ship order
];

export const RegularPOStatusTypes = [
  'APR', // Approved - Released to Store
  'PAPR', // Pending Approval
  'SMT', // Submitted to Vendor
  'PRCV', // Partially Received
];

export const ManualPOStatusTypes = [
  'SMT', // Submitted to Vendor
  'PRCV', // Partially Received
];

export const RcvdRegAndMPOTypes = [
  'ER', // External from RMS PO
  'DS', //	Direct To Store
  'EDN', // eCommerce drop-ship order
  'REG', // Regular
  'EO', //eCommerce Order
  'SDN', // Customer drop-ship order
];

export enum POSection {
  RegularPO = 'RegularPO',
  ManualPO = 'ManualPo',
  PreviouslyReceivedPO = 'PreviouslyReceivedPO',
}

export const DAYS_OF_WEEK = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const DATE_FORMAT = 'MM/dd/yyyy';

export enum REDIRECT_ORIGIN {
  MANUAL_PO_LIST = 'manual-po-list',
}

export enum REDIRECT_ACTION {
  RECEIVE_PO = 'receivepo',
}

export enum REDIRECT_PARAM_VALUE_KEY {
  PO_NUMBER = 'ponumber',
}
