import { RACButton, RACModal, withStyles } from '@rentacenter/racstrap';
import clsx from 'clsx';
import React from 'react';
import ReactToPrint from 'react-to-print';
import { PurchaseOrder, StoreDetail, User } from '../../types/types';
import ComponentToPrint from './ComponentToPrint';

export interface POReceivePrintModalProps {
  classes: any;
  purchaseOrders: PurchaseOrder[];
  selectedPOId: string | null;
  store?: StoreDetail;
  user?: User;
  onClose: () => void;
}

const useStyles = (theme: any) => ({
  dialogContent: {
    margin: `2rem 0`,
  },
  dialogActions: {
    justifyContent: 'flex-end',
    position: 'sticky',
    bottom: 0,
  },
  dialogRoot: {
    '& .MuiPaper-rounded': {
      borderRadius: theme.typography.pxToRem(12),
    },
    '& .MuiDialog-paperWidthXs': {
      maxWidth: theme.typography.pxToRem(500),
    },
    '& .MuiTypography-h5': {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(30),
    },
  },
  previewContainer: {
    height: theme.typography.pxToRem(420),
  },
});

// This has to be class component, in order to integrate with react-to-print
class PrintPreviouslyReceivedModal extends React.Component<POReceivePrintModalProps> {
  render() {
    const { classes, onClose, purchaseOrders, selectedPOId, store, user } =
      this.props;

    return (
      <RACModal
        isOpen
        classes={{
          dialogContent: classes.dialogContent,
          dialogActions: classes.dialogActions,
          dialog: classes.dialogRoot,
        }}
        maxWidth="lg"
        title="Previously Received PO"
        content={
          <div className={clsx(classes.previewContainer)}>
            <ComponentToPrint
              purchaseOrders={purchaseOrders}
              selectedPOId={selectedPOId}
              store={store}
              user={user}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              ref={(el) => (this.componentRef = el)}
            />
          </div>
        }
        onClose={onClose}
        buttons={
          <>
            <RACButton variant="outlined" color="secondary" onClick={onClose}>
              Cancel
            </RACButton>
            <ReactToPrint
              onAfterPrint={onClose}
              trigger={() => {
                return (
                  <RACButton variant="contained" color="primary">
                    OK
                  </RACButton>
                );
              }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              content={() => this.componentRef}
            />
          </>
        }
      />
    );
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default withStyles(useStyles)(PrintPreviouslyReceivedModal);
