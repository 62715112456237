import { RACModal, RACButton, makeStyles } from '@rentacenter/racstrap';
import React, { useState } from 'react';
import { useGlobalContext } from '../../context/global/GlobalContext';
import { reversePurchaseOrder } from '../../api/purchaseOrders';
import {
  ReversePORequest,
  ReversePO,
  ReversePODetail,
  PurchaseOrder,
  UpdatedPOInformation,
} from '../../types/types';

export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    textAlign: 'center',
    margin: `2rem 0 1.5rem`,
    height: '20%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(16),
  },
  dialogActions: {
    justifyContent: 'flex-end',
    position: 'sticky',
    bottom: 0,
  },
  dialogRoot: {
    height: '100%',
    '& .MuiPaper-rounded': {
      borderRadius: theme.typography.pxToRem(12),
    },
    '& .MuiDialog-paperScrollPaper': {
      width: '43%',
      minHeight: theme.typography.pxToRem(230),
      minWidth: theme.typography.pxToRem(300),
    },
    '& .MuiDialogTitle-root': {
      display: 'none',
    },
    '& .MuiTypography-h5': {
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 500,
      lineHeight: theme.typography.pxToRem(30),
    },
    '& .MuiDialogActions-root': {
      justifyContent: 'center',
    },
  },
  contentHeight: {
    height: '30%',
    overflow: 'scroll',
  },
  confirmSpan: {
    marginTop: '0.75rem',
  },
}));
export interface ConfirmPOModalProps {
  open: boolean;
  handleCallFinish: (success: boolean) => void;
  handleClose: () => void;
}

const CONFIRM_MESSAGE = 'Do you confirm this action?';
export const ReverseConfirm = ({
  open,
  handleCallFinish,
  handleClose,
}: ConfirmPOModalProps) => {
  const classes = useStyles();
  const [pending, setPending] = useState(false);
  const {
    previouslyReceived: {
      data: purchaseOrders,
      selectedItemId: selectedPO,
      updatedPO: itemList,
    },
  } = useGlobalContext();

  const handleConfirm = () => {
    setPending(true);

    const revPORequest = buildReversePORequest();

    reversePurchaseOrder(revPORequest)
      .then(() => {
        handleCallFinish(true);
      })
      .catch(() => {
        handleCallFinish(false);
      })
      .finally(() => {
        setPending(false);
      });
  };

  const buildReversePORequest = (): ReversePORequest => {
    const receivedDetails: ReversePODetail[] = itemList.map(
      (item: UpdatedPOInformation) => {
        return {
          purchaseOrderDetailId: item.purchaseOrderDetailId,
          // eslint-disable-next-line
          purchaseOrderDetailReceivedId: item.purchaseOrderDetailReceivedId!,
        };
      }
    );
    const reversePO: ReversePO = {
      // eslint-disable-next-line
      purchaseOrderId: getPOId(selectedPO!),
      receivedDetails: receivedDetails,
    };

    const res: ReversePORequest = {
      purchaseOrderReverse: reversePO,
    };
    return res;
  };

  const getPOId = (poNumber: string) => {
    const po: PurchaseOrder | undefined = purchaseOrders.find(
      (po) => po.purchaseOrderNumber === poNumber
    );
    if (po) {
      return po.purchaseOrderId;
    } else {
      return '';
    }
  };
  return (
    <RACModal
      isOpen={open}
      classes={{
        dialogContent: classes.dialogContent,
        dialog: classes.dialogRoot,
      }}
      maxWidth="lg"
      title="Cancel PO"
      content={
        <>
          <span>You are about to reverse items of this PO. </span>
          <span className={classes.confirmSpan}>{CONFIRM_MESSAGE}</span>
        </>
      }
      onClose={handleClose}
      buttons={
        <>
          <RACButton
            variant="contained"
            color="primary"
            disabled={false}
            onClick={handleClose}
          >
            No
          </RACButton>
          <RACButton
            disabled={pending}
            loading={pending}
            variant="outlined"
            color="secondary"
            onClick={handleConfirm}
          >
            Yes, Reverse
          </RACButton>
        </>
      }
    />
  );
};
