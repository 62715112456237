import { PurchaseOrder, UpdatedPOInformation } from '../../../types/types';
import { Action } from '../../../types/types';

export enum PreviouslyReceivedActionType {
  SET_PREVIOUSLY_RECEIVED = 'SET_PREVIOUSLY_RECEIVED',
  SET_SELECTED_PREVIOUSLY_RECEIVED_ID = 'SET_SELECTED_PREVIOUSLY_RECEIVED_ID',
  SET_UPDATED_ITEMS_DATA = 'SET_UPDATED_ITEMS_DATA',
}

export interface PreviouslyReceivedAction extends Action {
  readonly type: any;
  readonly payload: any;
}

export const setPreviouslyReceived = (
  purchaseOrders: PurchaseOrder[]
): PreviouslyReceivedAction => ({
  type: PreviouslyReceivedActionType.SET_PREVIOUSLY_RECEIVED,
  payload: purchaseOrders,
});

export const setSelectedPreviouslyReceivedId = (
  id: string
): PreviouslyReceivedAction => ({
  type: PreviouslyReceivedActionType.SET_SELECTED_PREVIOUSLY_RECEIVED_ID,
  payload: id,
});

export const setUpdatedItemsData = (
  updatedPO: UpdatedPOInformation[]
): PreviouslyReceivedAction => ({
  type: PreviouslyReceivedActionType.SET_UPDATED_ITEMS_DATA,
  payload: updatedPO,
});
