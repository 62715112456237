import React, {
  createContext,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from 'react';
import { POSearchFilter } from '../../types/types';
import { useLocation } from 'react-router-dom';
import {
  REDIRECT_ACTION,
  REDIRECT_ORIGIN,
  REDIRECT_PARAM_VALUE_KEY,
} from '../../constants/constants';

export const initialFilter = {
  poNumber: '',
  fromDate: '',
  toDate: '',
};

export interface SearchState {
  filter: POSearchFilter;
  searchedPOInSection: string;
  showLoader: boolean;
  manualPOLoading: boolean;
  regularPOLoading: boolean;
  previouslyReceivedPOLoading: boolean;
  noSearchItemFound: boolean;
  searchItemFound: boolean;
  manualPOReceive: string;
  reloadData: boolean;
}

export interface SearchDispatchState {
  setSearchFilter: (poNumber: string, fromDate: string, toDate: string) => void;
  setShowLoader: (value: boolean) => void;
  setSearchedPOInSection: (value: string) => void;
  setManualPOLoading: (value: boolean) => void;
  setRegularPOLoading: (value: boolean) => void;
  setPreviouslyReceivedPOLoading: (value: boolean) => void;
  setNoSearchItemFound: (value: boolean) => void;
  clearFilter: () => void;
  setSearchItemFound: (value: boolean) => void;
  setReloadData: (value: boolean) => void;
  setManualPOReceive: (value: string) => void;
}

export const SearchStateContext = createContext<SearchState>({} as SearchState);
export const SearchDispatchContext = createContext<SearchDispatchState>(
  {} as SearchDispatchState
);

const getPoNumberFromUrl = (search: string) => {
  const origin = new URLSearchParams(search).get('origin');
  const action = new URLSearchParams(search).get('action');
  const poNumber = new URLSearchParams(search).get(
    REDIRECT_PARAM_VALUE_KEY.PO_NUMBER
  );
  const isLandedManualPOList =
    origin === REDIRECT_ORIGIN.MANUAL_PO_LIST &&
    action === REDIRECT_ACTION.RECEIVE_PO;
  REDIRECT_PARAM_VALUE_KEY;

  return isLandedManualPOList && poNumber ? poNumber : '';
};

export const SearchProvider = (props: { children: ReactNode }) => {
  const location = useLocation();
  const search = location.search;

  const [manualPOReceive, setManualPOReceive] = useState(
    getPoNumberFromUrl(search)
  );
  const [filter, setFilter] = useState<POSearchFilter>(initialFilter);
  const [noSearchItemFound, setNoSearchItemFound] = useState(false);
  const [searchItemFound, setSearchItemFound] = useState(false);
  const [manualPOLoading, setManualPOLoading] = useState(false);
  const [regularPOLoading, setRegularPOLoading] = useState(false);
  const [previouslyReceivedPOLoading, setPreviouslyReceivedPOLoading] =
    useState(false);
  const [searchedPOInSection, setSearchedPOInSection] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const setSearchFilter = (
    poNumber: string,
    fromDate: string,
    toDate: string
  ) => {
    setSearchItemFound(false);
    setFilter({
      poNumber,
      fromDate,
      toDate,
    });
  };

  useEffect(() => {
    filter.poNumber && setShowLoader(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.poNumber, filter.fromDate, filter.toDate]);

  const clearFilter = () => setFilter(initialFilter);

  useEffect(() => {
    const allDataLoaded =
      !manualPOLoading && !previouslyReceivedPOLoading && !regularPOLoading;
    if (filter.poNumber && allDataLoaded) {
      setShowLoader(false);
    }

    if (filter.poNumber && allDataLoaded && !searchItemFound && !showLoader) {
      setNoSearchItemFound(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    manualPOLoading,
    previouslyReceivedPOLoading,
    regularPOLoading,
    searchedPOInSection,
  ]);

  useEffect(() => {
    const allDataLoaded =
      !manualPOLoading && !previouslyReceivedPOLoading && !regularPOLoading;

    if (filter.poNumber && allDataLoaded && !searchItemFound && !showLoader) {
      setNoSearchItemFound(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    manualPOLoading,
    previouslyReceivedPOLoading,
    regularPOLoading,
    showLoader,
  ]);

  useEffect(() => {
    if (
      reloadData &&
      manualPOLoading &&
      previouslyReceivedPOLoading &&
      regularPOLoading
    ) {
      setReloadData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    manualPOLoading,
    previouslyReceivedPOLoading,
    regularPOLoading,
    reloadData,
  ]);

  return (
    <SearchStateContext.Provider
      value={{
        filter,
        searchedPOInSection,
        showLoader,
        manualPOLoading,
        regularPOLoading,
        previouslyReceivedPOLoading,
        noSearchItemFound,
        searchItemFound,
        manualPOReceive,
        reloadData,
      }}
    >
      <SearchDispatchContext.Provider
        value={{
          setSearchFilter,
          setShowLoader,
          setSearchedPOInSection,
          clearFilter,
          setManualPOLoading,
          setRegularPOLoading,
          setPreviouslyReceivedPOLoading,
          setNoSearchItemFound,
          setSearchItemFound,
          setReloadData,
          setManualPOReceive,
        }}
      >
        {props.children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  );
};

export const useSearchDetails = () => useContext(SearchStateContext);

export const useSearchActions = () => useContext(SearchDispatchContext);
