import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AppRoute } from '../config/route-config';
import { UserProvider } from '../context/user/UserProvider';
import { StoreProvider } from '../context/Store/StoreProvider';
import { UserFetcher } from '../components/UserFetcher/UserFetcher';
import { ReceivingPO } from '../components/ReceivingPO/ReceivingPO';
import { GlobalContextProvider } from '../context/global/GlobalContextProvider';
import { SearchProvider } from '../context/Search/SearchProvider';

export const routesTestId = 'routesTestId';

export const Routes = () => (
  <UserProvider>
    {/* <PermissionsProvider> */}
    <UserFetcher />
    <StoreProvider>
      <GlobalContextProvider>
        <SearchProvider>
          <div data-testid={routesTestId}>
            <Switch>
              <Route exact path={AppRoute.Root}>
                <Redirect to={AppRoute.ReceivingPO} />
              </Route>
              <Route exact path={AppRoute.Index}>
                <Redirect to={AppRoute.ReceivingPO} />
              </Route>
              <Route exact path={AppRoute.ReceivingPO}>
                <ReceivingPO />
              </Route>
            </Switch>
          </div>
        </SearchProvider>
      </GlobalContextProvider>
    </StoreProvider>
    {/* </PermissionsProvider> */}
  </UserProvider>
);
